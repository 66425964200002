import BigNumber from 'bignumber.js/bignumber'

export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935',
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const addressMap = {
  uniswapFactory: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  uniswapFactoryV2: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  YFI: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  YCRV: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
  UNIAmpl: '0xc5be99a02c6857f9eac67bbce58df5572498f40c',
  WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  UNIRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  LINK: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  MKR: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  SNX: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
  COMP: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
  LEND: '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
  DRUGSYCRV: '0x2C7a51A357d5739C5C74Bf3C96816849d2c9F726',
}

export const contractAddresses = {
  drugs: {
    56: '0xadcBAEbaac0f775923eBDFb6B904399DA8801275',
  },
  originalGangster: {
    56: '0x5785FcAa31aBeba6cEA0e360C899c148FcE9476A',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  Hoes: {
    56: '0xfE60B258204398F008581775F08D2b43fb7b422b',
  },
  GunsStaker: {
    56: '0x85AB044e658c9EF2127DA97aA7AAA55bFE0eA756',
  },
  smartG2: {
    56: '0x644B8B9BB4D3B6a23DFbc1F9aa809D85F2166f2D',
  },
  smartG3: {
    56: '0x22eF65CAb21578cB085A18f2b44376916D4Acc86',
  },
  smartG4: {
    56: '0x66208594159D3F8bF475b598726C8ad880A63a63',
  },
  smartG5: {
    56: '0xf1254DE60C5dfd3Af6E7608A308c6f16c6CB773F',
  },
  smartG6: {
    56: '0x4bdcf6A051Ac3AA48f606DA3D64b65ec5b06F45C',
  },
  smartG7: {
    56: '0x510BA22db0fb8432d80f4355bD2cA95b53d1eC52',
  },
  smartG8: {
    56: '0x5CD2C95a3599D70e5c4b39Fb758B763640de5064',
  },
  smartG9: {
    56: '0x272cBEaeA73d3E4c717Bf67A2A3e50A17b33021c',
  },
  smartG10: {
    56: '0xb95F3f5F62F98308A57F84b548c306B852AEF879',
  },
  smartG11: {
    56: '0x2a1A101C9213fCf6844685d5886ea4107229b3db',
  },
  cred: {
    56: '0x571D0B55fe30eb1F6E68e8799F181c46De6B0059',
  },
  thugs: {
    56: '0xE10e9822A5de22F8761919310DDA35CD997d63c0',
  },
  olddrugs: {
    56: '0x5625eb03d999817941bad868bbf8a0eaf0749557',
  },
  BliqToken: {
    56: '0xc97fac34ceb0bb1b2bdaf3b59ada378b99111a22',
  },
  BHCToken: {
    56: '0xa6381c6fd8f40a44721ef4f61edc1a8ccca7bf3d',
  },
  BHCNew: {
    56: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
  },
  BHCSwap: {
    56: '0x5BdF8ce5bEB48DE9689fa0aE20D28eeA2A770409',
  },
  NARToken: {
    56: '0xa1303e6199b319a891b79685f0537d289af1fc83',
  },
  JGNToken: {
    56: '0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75',
  },
  bTRI: {
    56: '0x30C18C9cb3de61f2BD1974749A6f5725150208F3',
  },
  GunsToken: {
    56: '0x179983892eC36287f1F342443B671Ba549762241',
  },
  Ditto: {
    56: '0x233d91a0713155003fc4dce0afa871b508b3b715',
  },
  BUSD: {
    56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
}

/*
SLP Address on mainnet for reference
==========================================
0  USDT 0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852
1  USDC 0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc
2  DAI  0xa478c2975ab1ea89e8196811f51a7b7ade33eb11
3  sUSD 0xf80758ab42c3b07da84053fd88804bcb6baa4b5c
4  COMP 0xcffdded873554f362ac02f8fb1f02e5ada10516f
5  LEND 0xab3f9bf1d81ddb224a2014e98b238638824bcf20
6  SNX  0x43ae24960e5534731fc831386c07755a2dc33d47
7  UMA  0x88d97d199b9ed37c29d846d00d443de980832a22
8  LINK 0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974
9  BAND 0xf421c3f2e695c2d4c0765379ccace8ade4a480d9
10 AMPL 0xc5be99a02c6857f9eac67bbce58df5572498f40c
11 YFI  0x2fdbadf3c4d5a8666bc06645b8358ab803996e28
12 DRUGS 0xce84867c3c02b05dc570d0135103d3fb9cc19433
*/

export const supportedPools = [
  {
    pid: 1,
    lpAddresses: {
      56: '0x421df185ff87bc5f19bd5a90102a51452b70c4a4',
    },
    tokenAddresses: {
      56: '0x339550404Ca4d831D12B1b2e4768869997390010',
    },
    name: 'Super Drugs Bitches!',
    symbol: 'StreetSwap DRUGS-BNB',
    tokenSymbol: 'DRUGS',
    icon: '💊️',
    lppairurl:
      'https://streetswap.info/pair/0x421df185ff87bc5f19bd5a90102a51452b70c4a4',
    lpaddurl:
      'https://streetswap.vip/#/add/0x339550404Ca4d831D12B1b2e4768869997390010/BNB',
  },
  {
    pid: 2,
    lpAddresses: {
      56: '0x9354cab0f1083135439b23aa6c364329e578f39a',
    },
    tokenAddresses: {
      56: '0xe10e9822a5de22f8761919310dda35cd997d63c0',
    },
    name: 'Thugs Rule this Street',
    symbol: 'StreetSwap THUGS-BNB',
    tokenSymbol: 'THUGS',
    icon: '🖕️',
    lppairurl:
      'https://streetswap.info/pair/0x9354cab0f1083135439b23aa6c364329e578f39a',
    lpaddurl:
      'https://streetswap.vip/#/add/BNB/0xe10e9822a5de22f8761919310dda35cd997d63c0',
  },
  {
    pid: 3,
    lpAddresses: {
      56: '0xa5e5eeb6b51fa0fdc59915c81d75c83470bfa4f0',
    },
    tokenAddresses: {
      56: '0x179983892ec36287f1f342443b671ba549762241',
    },
    name: '🔫️ Guerilla Warfare 🔫️',
    symbol: 'StreetSwap: GUNS-BNB',
    tokenSymbol: 'GUNS',
    icon: '🔫️',
    lppairurl:
      'https://streetswap.info/pair/0xa5e5eeb6b51fa0fdc59915c81d75c83470bfa4f0',
    lpaddurl:
      'https://streetswap.vip/#/add/0x179983892ec36287f1f342443b671ba549762241/BNB',
  },
  {
    pid: 9,
    lpAddresses: {
      56: '0x9cbe4f200b474a1c33bffe7c6e531e1d47ecab46',
    },
    tokenAddresses: {
      56: '0x571d0b55fe30eb1f6e68e8799f181c46de6b0059',
    },
    name: 'Power of Street CRED',
    symbol: 'StreetSwap CRED-BNB',
    tokenSymbol: 'CRED',
    icon: '👊️',
    lppairurl:
      'https://streetswap.info/pair/0x9cbe4f200b474a1c33bffe7c6e531e1d47ecab46',
    lpaddurl:
      'https://streetswap.vip/#/add/0x571d0b55fe30eb1f6e68e8799f181c46de6b0059/BNB',
  },
  {
    pid: 4,
    lpAddresses: {
      56: '0xf2e4e3f9b58b3edac88ad11d689a23f3119a782d',
    },
    tokenAddresses: {
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    name: 'Dirty Street Cash!',
    symbol: 'StreetSwap BUSD-BNB',
    tokenSymbol: 'BUSD',
    icon: '💵️',
    lppairurl:
      'https://streetswap.info/pair/0xf2e4e3f9b58b3edac88ad11d689a23f3119a782d',
    lpaddurl:
      'https://streetswap.vip/#/add/BNB/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  {
    pid: 14,
    lpAddresses: {
      56: '0x223b53b64aa5f87d2de194a15701fc0bc7474a31',
    },
    tokenAddresses: {
      56: '0xca3f508b8e4dd382ee878a314789373d80a5190a',
    },
    name: 'Thugs Gettin Beefy',
    symbol: 'StreetSwap: BIFI-BNB',
    tokenSymbol: 'BIFI',
    icon: '🐮️',
    lppairurl:
      'https://streetswap.info/pair/0x223b53b64aa5f87d2de194a15701fc0bc7474a31',
    lpaddurl:
      'https://streetswap.vip/#/add/0xca3f508b8e4dd382ee878a314789373d80a5190a/BNB',
  },
  {
    pid: 30,
    lpAddresses: {
      56: '0x982ee1343c73688314990483dea924808231de4d',
    },
    tokenAddresses: {
      56: '0x30c18c9cb3de61f2bd1974749a6f5725150208f3',
    },
    name: 'Righteous THUG Trinity',
    symbol: 'StreetSwap: bTRI-BNB',
    tokenSymbol: 'bTRI',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0x982ee1343c73688314990483dea924808231de4d',
    lpaddurl:
      'https://streetswap.vip/#/add/0x30c18c9cb3de61f2bd1974749a6f5725150208f3/BNB',
  },
  {
    pid: 31,
    lpAddresses: {
      56: '0xf271f9cc3e3626394978a9c0c9582931fa3cadd1',
    },
    tokenAddresses: {
      56: '0x30c18c9cb3de61f2bd1974749a6f5725150208f3',
    },
    name: 'Three Thugs Enforcers',
    symbol: 'StreetSwap: bTRI-CRED',
    tokenSymbol: 'bTRI',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0xf271f9cc3e3626394978a9c0c9582931fa3cadd1',
    lpaddurl:
      'https://streetswap.vip/#/add/0x30c18c9cb3de61f2bd1974749a6f5725150208f3/0x571d0b55fe30eb1f6e68e8799f181c46de6b0059',
  },
  {
    pid: 32,
    lpAddresses: {
      56: '0x99ce2ec80940d03a30898421654a038e75ab54a8',
    },
    tokenAddresses: {
      56: '0x233d91a0713155003fc4dce0afa871b508b3b715',
    },
    name: 'THUGS Raining Ditto $$',
    symbol: 'StreetSwap: DITTO-BNB',
    tokenSymbol: 'DITTO',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0x99ce2ec80940d03a30898421654a038e75ab54a8',
    lpaddurl:
      'https://streetswap.vip/#/add/0x233d91a0713155003fc4dce0afa871b508b3b715/BNB',
  },
  {
    pid: 28,
    lpAddresses: {
      56: '0xd46e7f33f8788f87d6017074dc4e4d781d3df91e',
    },
    tokenAddresses: {
      56: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
    },
    name: 'Drug Fueled Happiness',
    symbol: 'StreetSwap: BHC-BNB',
    tokenSymbol: 'BHC',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0xd46e7f33f8788f87d6017074dc4e4d781d3df91e',
    lpaddurl:
      'https://streetswap.vip/#/add/0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4/BNB',
  },
  {
    pid: 29,
    lpAddresses: {
      56: '0xc7c4726d0a45e4e7392901a90dcb52ec4d2bd1a3',
    },
    tokenAddresses: {
      56: '0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75',
    },
    name: 'All Thugs Be Juggernauts',
    symbol: 'StreetSwap: JGN-BNB',
    tokenSymbol: 'JGN',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0xc7c4726d0a45e4e7392901a90dcb52ec4d2bd1a3',
    lpaddurl:
      'https://streetswap.vip/#/add/0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75/BNB',
  },
  {
    pid: 22,
    lpAddresses: {
      56: '0xf7f21a56b19546a77eababf23d8dca726caf7577',
    },
    tokenAddresses: {
      56: '0x4b0f1812e5df2a09796481ff14017e6005508003',
    },
    name: 'Trust Thug Loyalty',
    symbol: 'StreetSwap: TWT-BNB',
    tokenSymbol: 'TWT',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0xf7f21a56b19546a77eababf23d8dca726caf7577',
    lpaddurl:
      'https://streetswap.vip/#/add/0x4b0f1812e5df2a09796481ff14017e6005508003/BNB',
  },
  {
    pid: 23,
    lpAddresses: {
      56: '0xf08865069864a5a62eb4dd4b9dcb66834822a198',
    },
    tokenAddresses: {
      56: '0x47bead2563dcbf3bf2c9407fea4dc236faba485a',
    },
    name: 'Swiper Stay Swiping',
    symbol: 'StreetSwap: SXP-BNB',
    tokenSymbol: 'SXP',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0xf08865069864a5a62eb4dd4b9dcb66834822a198',
    lpaddurl:
      'https://streetswap.vip/#/add/0x47bead2563dcbf3bf2c9407fea4dc236faba485a/BNB',
  },
  {
    pid: 24,
    lpAddresses: {
      56: '0x52a7fbe0995607b74e77a367036e76bce11a3b44',
    },
    tokenAddresses: {
      56: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
    },
    name: 'Space Thugs on Venus',
    symbol: 'StreetSwap: XVS-BNB',
    tokenSymbol: 'XVS',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0x52a7fbe0995607b74e77a367036e76bce11a3b44',
    lpaddurl:
      'https://streetswap.vip/#/add/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63/BNB',
  },
  {
    pid: 25,
    lpAddresses: {
      56: '0x45ac15fb9650a790a0cd0f2b10c5ec4a6bbfcc2c',
    },
    tokenAddresses: {
      56: '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
    },
    name: 'An Injective Solstice',
    symbol: 'StreetSwap: INJ-BNB',
    tokenSymbol: 'INJ',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0x45ac15fb9650a790a0cd0f2b10c5ec4a6bbfcc2c',
    lpaddurl:
      'https://streetswap.vip/#/add/0xa2b726b1145a4773f68593cf171187d8ebe4d495/BNB',
  },
  {
    pid: 26,
    lpAddresses: {
      56: '0x0ff2d8b1a11195526f42905892bcb29e7b9f338d',
    },
    tokenAddresses: {
      56: '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b',
    },
    name: 'Thugs Unifi BSC',
    symbol: 'StreetSwap: UNFI-BNB',
    tokenSymbol: 'UNFI',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0x0ff2d8b1a11195526f42905892bcb29e7b9f338d',
    lpaddurl:
      'https://streetswap.vip/#/add/0x728c5bac3c3e370e372fc4671f9ef6916b814d8b/BNB',
  },
  {
    pid: 27,
    lpAddresses: {
      56: '0x8ea481fe8e35c74355daf9b5f6b3b69f3c864afc',
    },
    tokenAddresses: {
      56: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    },
    name: 'Thugify Charlie Lee',
    symbol: 'StreetSwap: LTC-BNB',
    tokenSymbol: 'LTC',
    icon: '🛡️',
    lppairurl:
      'https://streetswap.info/pair/0x8ea481fe8e35c74355daf9b5f6b3b69f3c864afc',
    lpaddurl:
      'https://streetswap.vip/#/add/0x4338665cbb7b2485a8855a139b75d5e34ab0db94/BNB',
  },
  {
    pid: 19,
    lpAddresses: {
      56: '0xf5ad2466541d5d970521e3d7e3e9e62549ee8c36',
    },
    tokenAddresses: {
      56: '0xf79037f6f6be66832de4e7516be52826bc3cbcc4',
    },
    name: 'Super Hard Proto',
    symbol: 'StreetSwap: HARD-BNB',
    tokenSymbol: 'HARD',
    icon: '⛓️',
    lppairurl:
      'https://streetswap.info/pair/0xf5ad2466541d5d970521e3d7e3e9e62549ee8c36',
    lpaddurl:
      'https://streetswap.vip/#/add/0xf79037f6f6be66832de4e7516be52826bc3cbcc4/BNB',
  },
  {
    pid: 20,
    lpAddresses: {
      56: '0x388ea96b8e384588ea17ec16d7d89cf591cb8b46',
    },
    tokenAddresses: {
      56: '0x63870a18b6e42b01ef1ad8a2302ef50b7132054f',
    },
    name: 'Winner 🐔️ Dinner',
    symbol: 'StreetSwap: BLINK-BNB',
    tokenSymbol: 'BLK',
    icon: '🎲️',
    lppairurl:
      'https://streetswap.info/pair/0x388ea96b8e384588ea17ec16d7d89cf591cb8b46',
    lpaddurl:
      'https://streetswap.vip/#/add/0x63870a18b6e42b01ef1ad8a2302ef50b7132054f/BNB',
  },
  {
    pid: 21,
    lpAddresses: {
      56: '0x8d27dccb0fb3d6621fa4f4155d719f4af159286e',
    },
    tokenAddresses: {
      56: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
    },
    name: 'Alpha Thugs Trappin',
    symbol: 'StreetSwap: ALPHA-BNB',
    tokenSymbol: 'ALPHA',
    icon: '🅰️',
    lppairurl:
      'https://streetswap.info/pair/0x8d27dccb0fb3d6621fa4f4155d719f4af159286e',
    lpaddurl:
      'https://streetswap.vip/#/add/0xa1faa113cbe53436df28ff0aee54275c13b40975/BNB',
  },
  {
    pid: 5,
    lpAddresses: {
      56: '0xdfb193940e1317f38e91568fdb05efe18ee4a3c7',
    },
    tokenAddresses: {
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    name: 'Binance Bitcoin Baby!',
    symbol: 'StreetSwap BTC-BNB',
    tokenSymbol: 'BTCB',
    icon: '🌝️',
    lppairurl:
      'https://streetswap.info/pair/0xdfb193940e1317f38e91568fdb05efe18ee4a3c7',
    lpaddurl:
      'https://streetswap.vip/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/BNB',
  },
  {
    pid: 6,
    lpAddresses: {
      56: '0x75115c644f9661a761a333ba0a38e42b1649f143',
    },
    tokenAddresses: {
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    name: 'Slanging that Ether!',
    symbol: 'StreetSwap ETH-BNB',
    tokenSymbol: 'ETH',
    icon: '🌝️',
    lppairurl:
      'https://streetswap.info/pair/0x75115c644f9661a761a333ba0a38e42b1649f143',
    lpaddurl:
      'https://streetswap.vip/#/add/0x2170ed0880ac9a755fd29b2688956bd959f933f8/BNB',
  },
  {
    pid: 7,
    lpAddresses: {
      56: '0x40257481f09db2211a3274b34554bbb00b0a7fb1',
    },
    tokenAddresses: {
      56: '0xe10e9822a5de22f8761919310dda35cd997d63c0',
    },
    name: 'THUGS on Pancake!',
    symbol: 'PancakeSwap THUGS-BNB',
    tokenSymbol: 'THUGS',
    icon: '🖕️',
    lppairurl:
      'https://pancakeswap.info/pair/0x40257481f09db2211a3274b34554bbb00b0a7fb1',
    lpaddurl:
      'https://exchange.pancakeswap.finance/#/add/ETH/0xe10e9822a5de22f8761919310dda35cd997d63c0',
  },
  {
    pid: 8,
    lpAddresses: {
      56: '0x40Ff83ac2890DD5469f5eCa2c17dcAf10ee09957',
    },
    tokenAddresses: {
      56: '0xe10e9822a5de22f8761919310dda35cd997d63c0',
    },
    name: 'THUGS Riding Narwhals',
    symbol: 'NarwhalSwap THUGS-BNB',
    tokenSymbol: 'THUGS',
    icon: '🖕️',
    lppairurl:
      'https://info.narwhalswap.org/pair/0x40ff83ac2890dd5469f5eca2c17dcaf10ee09957',
    lpaddurl:
      'https://narwhalswap.org/#/page/add/BNB/0xe10e9822a5de22f8761919310dda35cd997d63c0',
  },
  {
    pid: 10,
    lpAddresses: {
      56: '0xed8ecb790be568461d85cf82c386e51124e46a52',
    },
    tokenAddresses: {
      56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    },
    name: 'DecentraDollars Yo',
    symbol: 'StreetSwap DAI-BNB',
    tokenSymbol: 'DAI',
    icon: '💵️',
    lppairurl:
      'https://streetswap.info/pair/0xed8ecb790be568461d85cf82c386e51124e46a52',
    lpaddurl:
      'https://streetswap.vip/#/add/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3/BNB',
  },
  {
    pid: 11,
    lpAddresses: {
      56: '0x0d29724d1834fc65869812bae5d63dce8acb7921',
    },
    tokenAddresses: {
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    name: 'Secret Tether Stash',
    symbol: 'StreetSwap USDT-BNB',
    tokenSymbol: 'USDT',
    icon: '💵️',
    lppairurl:
      'https://streetswap.info/pair/0x0d29724d1834fc65869812bae5d63dce8acb7921',
    lpaddurl:
      'https://streetswap.vip/#/add/0x55d398326f99059ff775485246999027b3197955/BNB',
  },
  {
    pid: 12,
    lpAddresses: {
      56: '0xe835ed82c7d9d018f8f5173eecfbb56f29f38570',
    },
    tokenAddresses: {
      56: '0xc97fac34ceb0bb1b2bdaf3b59ada378b99111a22',
    },
    name: 'Burning Liquid Drugs',
    symbol: 'StreetSwap BLIQ-BNB',
    tokenSymbol: 'BLIQ',
    icon: '💧️',
    lppairurl:
      'https://streetswap.info/pair/0xe835ed82c7d9d018f8f5173eecfbb56f29f38570',
    lpaddurl:
      'https://streetswap.vip/#/add/0xc97fac34ceb0bb1b2bdaf3b59ada378b99111a22/BNB',
  },
  {
    pid: 13,
    lpAddresses: {
      56: '0x44e89861096371246ea8e3e86d97781ea24fbb29',
    },
    tokenAddresses: {
      56: '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6',
    },
    name: 'For The MudCrab',
    symbol: 'StreetSwap: EOS-BNB',
    tokenSymbol: 'EOS',
    icon: '🦀️',
    lppairurl:
      'https://streetswap.info/pair/0x44e89861096371246ea8e3e86d97781ea24fbb29',
    lpaddurl:
      'https://streetswap.vip/#/add/0x56b6fb708fc5732dec1afc8d8556423a2edccbd6/BNB',
  },
  {
    pid: 16,
    lpAddresses: {
      56: '0xce7b1bc3ff9115b64bf4d6b1549f8339d02762f4',
    },
    tokenAddresses: {
      56: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
    },
    name: 'Roger Ver Brr',
    symbol: 'StreetSwap: BCH-BNB',
    tokenSymbol: 'BCH',
    icon: '🌚️',
    lppairurl:
      'https://streetswap.info/pair/0xce7b1bc3ff9115b64bf4d6b1549f8339d02762f4',
    lpaddurl:
      'https://streetswap.vip/#/add/0x8ff795a6f4d97e7887c79bea79aba5cc76444adf/BNB',
  },
  {
    pid: 17,
    lpAddresses: {
      56: '0x2c92d0390f95477c70cb7b4b92050b0db5d04a1e',
    },
    tokenAddresses: {
      56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    },
    name: 'ChainLink Brink',
    symbol: 'StreetSwap: LINK-BNB',
    tokenSymbol: 'LINK',
    icon: '⛓️',
    lppairurl:
      'https://streetswap.info/pair/0x2c92d0390f95477c70cb7b4b92050b0db5d04a1e',
    lpaddurl:
      'https://streetswap.vip/#/add/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd/BNB',
  },
  {
    pid: 18,
    lpAddresses: {
      56: '0x1389003bcb228586648721a4f6187004dae01c51',
    },
    tokenAddresses: {
      56: '0xa1303e6199b319a891b79685f0537d289af1fc83',
    },
    name: 'The Mystical Narwhal',
    symbol: 'StreetSwap: NAR-BNB',
    tokenSymbol: 'NAR',
    icon: '🐳️',
    lppairurl:
      'https://streetswap.info/pair/0x1389003bcb228586648721a4f6187004dae01c51',
    lpaddurl:
      'https://streetswap.vip/#/add/0xa1303e6199b319a891b79685f0537d289af1fc83/BNB',
  },
  {
    pid: 15,
    lpAddresses: {
      56: '0x0dc44e742941d4c935d6113a3b643656c5092d93',
    },
    tokenAddresses: {
      56: '0xa6381c6fd8f40a44721ef4f61edc1a8ccca7bf3d',
    },
    name: 'Unstake Now',
    symbol: 'StreetSwap: BHC-BNB',
    tokenSymbol: 'OLDBHC',
    icon: '😃️',
    lppairurl:
      'https://streetswap.info/pair/0x0dc44e742941d4c935d6113a3b643656c5092d93',
    lpaddurl:
      'https://streetswap.vip/#/add/0xa6381c6fd8f40a44721ef4f61edc1a8ccca7bf3d/BNB',
  },
]
