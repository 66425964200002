import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { Contracts } from './lib/contracts.js'
import { Account } from './lib/accounts.js'
import { EVM } from './lib/evm.js'

import { contractAddresses } from './lib/constants.js'

export class Drugs {
  constructor(provider, networkId, testing, options) {
    var realProvider

    if (typeof provider === 'string') {
      if (provider.includes('wss')) {
        realProvider = new Web3.providers.WebsocketProvider(
          provider,
          options.ethereumNodeTimeout || 10000,
        )
      } else {
        realProvider = new Web3.providers.HttpProvider(
          provider,
          options.ethereumNodeTimeout || 10000,
        )
      }
    } else {
      realProvider = provider
    }

    this.web3 = new Web3(realProvider)

    if (testing) {
      this.testing = new EVM(realProvider)
      this.snapshot = this.testing.snapshot()
    }

    if (options.defaultAccount) {
      this.web3.eth.defaultAccount = options.defaultAccount
    }
    this.contracts = new Contracts(realProvider, networkId, this.web3, options)
    this.drugsAddress = contractAddresses.drugs[networkId]
    this.originalGangsterAddress = contractAddresses.originalGangster[networkId]
    this.gunsStakerAddress = contractAddresses.GunsStaker[networkId]
    this.thugsAddress = contractAddresses.thugs[networkId]
    this.olddrugsAddress = contractAddresses.olddrugs[networkId]
    this.credAddress = contractAddresses.cred[networkId]
    this.gunsTokenAddress = contractAddresses.GunsToken[networkId]
    this.smartG2Address = contractAddresses.smartG2[networkId]
    this.BliqTokenAddress = contractAddresses.BliqToken[networkId]
    this.smartG3Address = contractAddresses.smartG3[networkId]
    this.BHCTokenAddress = contractAddresses.BHCToken[networkId]
    this.BHCNewAddress = contractAddresses.BHCNew[networkId]
    this.BHCSwapAddress = contractAddresses.BHCSwap[networkId]
    this.smartG4Address = contractAddresses.smartG4[networkId]
    this.smartG5Address = contractAddresses.smartG5[networkId]
    this.smartG6Address = contractAddresses.smartG6[networkId]
    this.smartG7Address = contractAddresses.smartG7[networkId]
    this.smartG8Address = contractAddresses.smartG8[networkId]
    this.smartG9Address = contractAddresses.smartG9[networkId]
    this.smartG10Address = contractAddresses.smartG10[networkId]
    this.smartG11Address = contractAddresses.smartG11[networkId]
    this.bTRIAddress = contractAddresses.bTRI[networkId]
    this.DittoAddress = contractAddresses.Ditto[networkId]
    this.NARTokenAddress = contractAddresses.NARToken[networkId]
    this.JGNTokenAddress = contractAddresses.JGNToken[networkId]
    this.wbnbAddress = contractAddresses.wbnb[networkId]
  }

  async resetEVM() {
    this.testing.resetEVM(this.snapshot)
  }

  addAccount(address, number) {
    this.accounts.push(new Account(this.contracts, address, number))
  }

  setProvider(provider, networkId) {
    this.web3.setProvider(provider)
    this.contracts.setProvider(provider, networkId)
    this.operation.setNetworkId(networkId)
  }

  setDefaultAccount(account) {
    this.web3.eth.defaultAccount = account
    this.contracts.setDefaultAccount(account)
  }

  getDefaultAccount() {
    return this.web3.eth.defaultAccount
  }

  loadAccount(account) {
    const newAccount = this.web3.eth.accounts.wallet.add(account.privateKey)

    if (
      !newAccount ||
      (account.address &&
        account.address.toLowerCase() !== newAccount.address.toLowerCase())
    ) {
      throw new Error(`Loaded account address mismatch.
        Expected ${account.address}, got ${
        newAccount ? newAccount.address : null
      }`)
    }
  }

  toBigN(a) {
    return BigNumber(a)
  }
}
