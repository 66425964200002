export const VAULT_FETCH_BALANCES_BEGIN = 'VAULT_FETCH_BALANCES_BEGIN';
export const VAULT_FETCH_BALANCES_SUCCESS = 'VAULT_FETCH_BALANCES_SUCCESS';
export const VAULT_FETCH_BALANCES_FAILURE = 'VAULT_FETCH_BALANCES_FAILURE';

export const VAULT_FETCH_POOL_BALANCES_BEGIN = 'VAULT_FETCH_POOL_BALANCES_BEGIN';
export const VAULT_FETCH_POOL_BALANCES_SUCCESS = 'VAULT_FETCH_POOL_BALANCES_SUCCESS';
export const VAULT_FETCH_POOL_BALANCES_FAILURE = 'VAULT_FETCH_POOL_BALANCES_FAILURE';

export const VAULT_FETCH_USER_POOL_BALANCES_BEGIN = 'VAULT_FETCH_USER_POOL_BALANCES_BEGIN';
export const VAULT_FETCH_USER_POOL_BALANCES_SUCCESS = 'VAULT_FETCH_USER_POOL_BALANCES_SUCCESS';
export const VAULT_FETCH_USER_POOL_BALANCES_FAILURE = 'VAULT_FETCH_USER_POOL_BALANCES_FAILURE';

export const VAULT_FETCH_APPROVAL_BEGIN = 'VAULT_FETCH_APPROVAL_BEGIN';
export const VAULT_FETCH_APPROVAL_SUCCESS = 'VAULT_FETCH_APPROVAL_SUCCESS';
export const VAULT_FETCH_APPROVAL_FAILURE = 'VAULT_FETCH_APPROVAL_FAILURE';

export const VAULT_FETCH_DEPOSIT_BEGIN = 'VAULT_FETCH_DEPOSIT_BEGIN';
export const VAULT_FETCH_DEPOSIT_SUCCESS = 'VAULT_FETCH_DEPOSIT_SUCCESS';
export const VAULT_FETCH_DEPOSIT_FAILURE = 'VAULT_FETCH_DEPOSIT_FAILURE';

export const VAULT_FETCH_WITHDRAW_BEGIN = 'VAULT_FETCH_WITHDRAW_BEGIN';
export const VAULT_FETCH_WITHDRAW_SUCCESS = 'VAULT_FETCH_WITHDRAW_SUCCESS';
export const VAULT_FETCH_WITHDRAW_FAILURE = 'VAULT_FETCH_WITHDRAW_FAILURE';

export const VAULT_FETCH_CONTRACT_APY_BEGIN = 'VAULT_FETCH_CONTRACT_APY_BEGIN';
export const VAULT_FETCH_CONTRACT_APY_SUCCESS = 'VAULT_FETCH_CONTRACT_APY_SUCCESS';
export const VAULT_FETCH_CONTRACT_APY_FAILURE = 'VAULT_FETCH_CONTRACT_APY_FAILURE';

export const VAULT_FETCH_STRATEGY_HARVEST_BEGIN = 'VAULT_FETCH_STRATEGY_HARVEST_BEGIN';
export const VAULT_FETCH_STRATEGY_HARVEST_SUCCESS = 'VAULT_FETCH_STRATEGY_HARVEST_SUCCESS';
export const VAULT_FETCH_STRATEGY_HARVEST_FAILURE = 'VAULT_FETCH_STRATEGY_HARVEST_FAILURE';