import React from 'react'
import styled from 'styled-components'
/* import panelHighlight from '../../assets/img/panel_highlight.png'
import panelShading from '../../assets/img/panel_shading.png'
 */
interface CardProps {
  size?: string
}

const Card: React.FC<CardProps> = ({ children, size }) => {
  let position = 0
  switch (size) {
    case 'sm':
      position = 50
      break
    case 'default':
    default:
      position = 105
  }

  return <StyledCard position={position}>{children}</StyledCard>
}

interface StyleProps {
  position: number
}

const StyledCard = styled.div<StyleProps>`
  position: relative;
  background-position: -5px -5px,
    ${(props) => `${props.position}%`} ${(props) => `${props.position}%`};
  background-repeat: no-repeat;
  background-color: hotpink;
  border: 2px solid yellow;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export default Card
