export const pools = [
  {
    id: 'street-bhc-bnb',
    logo: 'thugs/BHC-BNB.png',
    name: 'BHC-BNB TLP',
    token: 'BHC-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBHC-BNB',
    earnedTokenAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
    earnContractAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
    defaultApy: 27.27043,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-bhc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'BHC', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-jgn-bnb',
    logo: 'thugs/JGN-BNB.png',
    name: 'JGN-BNB TLP',
    token: 'JGN-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xc7C4726D0A45E4e7392901A90dCB52Ec4d2Bd1a3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetJGN-BNB',
    earnedTokenAddress: '0x34199dc9ad1bD3D4A6EC70c120B8780e3A2Ca6C7',
    earnContractAddress: '0x34199dc9ad1bD3D4A6EC70c120B8780e3A2Ca6C7',
    defaultApy: 36.100556,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-jgn-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'JGN', 'BNB' ],
    callFee: 1
  },
  {
    id: 'thugs-drugs-drugs',
    logo: 'thugs/DRUGS.png',
    name: 'DRUGS v2',
    token: 'DRUGS v2',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsGunsV2',
    earnedTokenAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
    earnContractAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
    defaultApy: 5.1605,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs',
    oracleId: '0x339550404Ca4d831D12B1b2e4768869997390010_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'DRUGS' ],
    callFee: 0.5
  },
  {
    id: 'thugs-drugs-bhc',
    logo: 'thugs/DRUGS.png',
    name: 'DRUGS BHC',
    token: 'DRUGS​',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsBHC',
    earnedTokenAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
    earnContractAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
    defaultApy: 507.937,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs',
    oracleId: '0x339550404Ca4d831D12B1b2e4768869997390010_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'DRUGS' ],
    callFee: 0.5
  },
  {
    id: 'thugs-drugs-jgn',
    logo: 'thugs/DRUGS.png',
    name: 'DRUGS JGN',
    token: 'DRUGS​​',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsJGN',
    earnedTokenAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
    earnContractAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
    defaultApy: 20.3656,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs',
    oracleId: '0x339550404Ca4d831D12B1b2e4768869997390010_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'DRUGS' ],
    callFee: 0.5
  },
  // {
  //   id: 'jetfuel-fuel-bnb-ss',
  //   logo: 'jetfuel/fuel-bnb-ss.png',
  //   name: 'FUEL-BNB TLP',
  //   token: 'FUEL-BNB TLP',
  //   tokenDescription: 'Uses: Traphouse',
  //   tokenAddress: '0xd26092c6ce6FB359302A9453dc0c7f312794Ef7E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfFUEL-BNB-SS',
  //   earnedTokenAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
  //   earnContractAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
  //   defaultApy: 16896.80118,
  //   unstableApy: false,
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'jetfuel-lp',
  //   oracleId: 'jetfuel-fuel-bnb-ss',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'JetFuel',
  //   assets: [ 'FUEL', 'BNB' ],
  //   callFee: 1
  // },
  // {
  //   id: 'jetfuel-bhc-bnb',
  //   logo: 'jetfuel/bhc-bnb.png',
  //   name: 'BHC-BNB LP',
  //   token: 'BHC-BNB LP',
  //   tokenDescription: 'Uses: Traphouse',
  //   tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfBHC-BNB',
  //   earnedTokenAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
  //   earnContractAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
  //   defaultApy: 792.70996,
  //   unstableApy: false,
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'jetfuel-lp',
  //   oracleId: 'jetfuel-bhc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'JetFuel',
  //   assets: [ 'BHC', 'BNB' ],
  //   callFee: 1
  // },
  {
    id: 'street-twt-bnb',
    logo: 'thugs/TWT-BNB.png',
    name: 'TWT-BNB TLP',
    token: 'TWT-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xf7f21A56B19546A77EABABf23d8dca726CaF7577',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetTWT-BNB',
    earnedTokenAddress: '0x23f81b26e7dfB44832f627Ee533399285a11e711',
    earnContractAddress: '0x23f81b26e7dfB44832f627Ee533399285a11e711',
    defaultApy: 1.68059,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-twt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'TWT', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-sxp-bnb',
    logo: 'thugs/SXP-BNB.png',
    name: 'SXP-BNB TLP',
    token: 'SXP-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xf08865069864A5a62EB4DD4b9dcB66834822a198',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetSXP-BNB',
    earnedTokenAddress: '0x0F5ef847595236Bb70833825FC9Bf0ef982CDD4C',
    earnContractAddress: '0x0F5ef847595236Bb70833825FC9Bf0ef982CDD4C',
    defaultApy: 0.78814,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-sxp-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'SXP', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-xvs-bnb',
    logo: 'thugs/XVS-BNB.png',
    name: 'XVS-BNB TLP',
    token: 'XVS-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x52a7FBe0995607B74e77A367036E76BCE11A3B44',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetXVS-BNB',
    earnedTokenAddress: '0x7ca3761D9886D5Ac233E58d5B2E60174B3A94b82',
    earnContractAddress: '0x7ca3761D9886D5Ac233E58d5B2E60174B3A94b82',
    defaultApy: 0.90858,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-xvs-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'XVS', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-inj-bnb',
    logo: 'thugs/INJ-BNB.png',
    name: 'INJ-BNB TLP',
    token: 'INJ-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x45ac15fb9650a790A0cD0f2b10c5EC4a6BbFCc2c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetINJ-BNB',
    earnedTokenAddress: '0xFfEf22C020486a4118d4274a6D0573a80276d4b4',
    earnContractAddress: '0xFfEf22C020486a4118d4274a6D0573a80276d4b4',
    defaultApy: 0.89904,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-inj-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'INJ', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-unfi-bnb',
    logo: 'thugs/UNFI-BNB.png',
    name: 'UNFI-BNB TLP',
    token: 'UNFI-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x0fF2D8B1A11195526f42905892bCb29e7b9F338D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetUNFI-BNB',
    earnedTokenAddress: '0x8F61350871B986B098A6757e3953CEF697D46025',
    earnContractAddress: '0x8F61350871B986B098A6757e3953CEF697D46025',
    defaultApy: 2.61942,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-unfi-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'UNFI', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-ltc-bnb',
    logo: 'thugs/LTC-BNB.png',
    name: 'LTC-BNB TLP',
    token: 'LTC-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x8Ea481FE8e35c74355DaF9B5f6b3b69F3c864aFc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetLTC-BNB',
    earnedTokenAddress: '0x40BBdaD2e12A3E9900F2e6e6A5B605c36182BEa0',
    earnContractAddress: '0x40BBdaD2e12A3E9900F2e6e6A5B605c36182BEa0',
    defaultApy: 0.74373,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-ltc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'LTC', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-alpha-bnb',
    logo: 'thugs/ALPHA-BNB.png',
    name: 'ALPHA-BNB TLP',
    token: 'ALPHA-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x8d27dccB0fb3d6621fA4F4155d719f4AF159286e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetALPHA-BNB',
    earnedTokenAddress: '0x1061c2962fccD794C4131b3c13058302618329E9',
    earnContractAddress: '0x1061c2962fccD794C4131b3c13058302618329E9',
    defaultApy: 1.43981,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-alpha-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'ALPHA', 'BNB' ],
    callFee: 1
  },
  {
    id: 'street-bch-bnb',
    logo: 'thugs/BCH-BNB.png',
    name: 'BCH-BNB TLP',
    token: 'BCH-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xcE7b1Bc3ff9115B64Bf4D6B1549f8339D02762F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBCH-BNB',
    earnedTokenAddress: '0x1bcc82ce9b387E16fC383af563fd400074E3f060',
    earnContractAddress: '0x1bcc82ce9b387E16fC383af563fd400074E3f060',
    defaultApy: 1.18469,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-bch-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'BCH', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-link-bnb',
    logo: 'thugs/LINK-BNB.png',
    name: 'LINK-BNB TLP',
    token: 'LINK-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x2c92d0390f95477c70cb7B4B92050B0Db5D04a1E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetLINK-BNB',
    earnedTokenAddress: '0x3DbBDFA0A412Fd15ef9fc4CEb3Fcc19Ff329980b',
    earnContractAddress: '0x3DbBDFA0A412Fd15ef9fc4CEb3Fcc19Ff329980b',
    defaultApy: 0.74643,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-link-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'LINK', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-nar-bnb',
    logo: 'thugs/NAR-BNB.png',
    name: 'NAR-BNB TLP',
    token: 'NAR-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x1389003bcb228586648721A4F6187004dAE01c51',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetNAR-BNB',
    earnedTokenAddress: '0x06fb2e27155c2a551aF34E80aE67f569673dDD73',
    earnContractAddress: '0x06fb2e27155c2a551aF34E80aE67f569673dDD73',
    defaultApy: 13.6601,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-nar-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'NAR', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-bifi-bnb',
    logo: 'thugs/BIFI-BNB.png',
    name: 'BIFI-BNB TLP',
    token: 'BIFI-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x223b53b64aa5f87d2de194a15701fc0bc7474a31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBIFI-BNB',
    earnedTokenAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
    earnContractAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
    defaultApy: 3.635,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-bifi-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'BIFI', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-drugs-bnb',
    logo: 'thugs/DRUGS-BNB.png',
    name: 'DRUGS-BNB LP v2',
    token: 'DRUGS-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x421DF185Ff87bc5f19bd5a90102A51452b70c4A4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetDRUGS-BNBV2',
    earnedTokenAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
    earnContractAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
    defaultApy: 77.9396,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-drugs-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'DRUGS', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-thugs-bnb',
    logo: 'thugs/THUGS-BNB.png',
    name: 'THUGS-BNB LP v2',
    token: 'THUGS-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x9354caB0F1083135439B23aA6c364329e578F39a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetTHUGS-BNBV2',
    earnedTokenAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
    earnContractAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
    defaultApy: 55.8904,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-thugs-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'THUGS', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-cred-bnb',
    logo: 'thugs/CRED-BNB.png',
    name: 'CRED-BNB LP',
    token: 'CRED-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x9CBE4F200b474a1C33bfFe7c6e531e1D47ECab46',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetCRED-BNB',
    earnedTokenAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
    earnContractAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
    defaultApy: 4.08061,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-cred-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'CRED', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-guns-bnb',
    logo: 'thugs/GUNS-BNB.png',
    name: 'GUNS-BNB LP v2',
    token: 'GUNS-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xa5e5eeB6b51fa0fDc59915C81d75C83470BFA4f0',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-guns-bnb-lp',
    earnedToken: 'mooStreetGUNS-BNBV2',
    earnedTokenAddress: '0x68aE2aB6448884714DDf11C8d55ea7578C65D502',
    earnContractAddress: '0x68aE2aB6448884714DDf11C8d55ea7578C65D502',
    defaultApy: 28.0494,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-guns-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'GUNS', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-busd-bnb',
    logo: 'thugs/BUSD-BNB.png',
    name: 'BUSD-BNB LP v2',
    token: 'BUSD-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xf2e4E3F9B58b3eDaC88Ad11D689a23f3119a782D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBUSD-BNBV2',
    earnedTokenAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
    earnContractAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
    defaultApy: 0.79818,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'BUSD', 'BNB' ],
    callFee: 0.5
  },
  // {
  //   id: 'street-btc-bnb',
  //   logo: 'thugs/BTC-BNB.png',
  //   name: 'BTC-BNB LP v2',
  //   token: 'BTC-BNB TLP',
  //   tokenDescription: 'Uses: Traphouse',
  //   tokenAddress: '0xdFb193940E1317f38e91568fdb05EFE18ee4A3c7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-btc-bnb-lp',
  //   earnedToken: 'mooStreetBTC-BNBV2',
  //   earnedTokenAddress: '0x498515AB07d72d24A85343322834a66e79e146f8',
  //   earnContractAddress: '0x498515AB07d72d24A85343322834a66e79e146f8',
  //   defaultApy: 0.70466,
  //   unstableApy: false,
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'thugs-lp',
  //   oracleId: 'street-btc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Thugs',
  //   assets: [ 'BTC', 'BNB' ],
  //   callFee: 0.5
  // },
  {
    id: 'street-eth-bnb',
    logo: 'thugs/ETH-BNB.png',
    name: 'ETH-BNB LP v2',
    token: 'ETH-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x75115C644F9661A761A333Ba0A38e42B1649f143',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-eth-bnb-lp',
    earnedToken: 'mooStreetETH-BNBV2',
    earnedTokenAddress: '0x5EA6fE190f9F7B814fDc438A6CC448d094740588',
    earnContractAddress: '0x5EA6fE190f9F7B814fDc438A6CC448d094740588',
    defaultApy: 0.73295,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-eth-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'ETH', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-panacakelp-thugs-bnb',
    logo: 'thugs/THUGS-BNB.png',
    name: 'THUGS-BNB Pancake LP v2',
    token: 'THUGS-BNB Pancake LP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x40257481f09db2211a3274b34554bbb00b0a7fb1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetPancakeLP-THUGS-BNBV2',
    earnedTokenAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
    earnContractAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
    defaultApy: 8.6891,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-panacakelp-thugs-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'THUGS', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-narwhallp-thugs-bnb',
    logo: 'thugs/THUGS-BNB.png',
    name: 'THUGS-BNB Narwhal LP v2',
    token: 'THUGS-BNB Narwhal LP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x40Ff83ac2890DD5469f5eCa2c17dcAf10ee09957',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetNarwhalLP-THUGS-BNBV2',
    earnedTokenAddress: '0xcB60700DB502F9908B8656C38D052c1BD54D291c',
    earnContractAddress: '0xcB60700DB502F9908B8656C38D052c1BD54D291c',
    defaultApy: 9.6102,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-narwhallp-thugs-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'THUGS', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-dai-bnb',
    logo: 'thugs/DAI-BNB.png',
    name: 'DAI-BNB LP',
    token: 'DAI-BNB LP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xed8Ecb790Be568461d85Cf82C386E51124E46a52',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetDAI-BNB',
    earnedTokenAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
    earnContractAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
    defaultApy: 0.9723,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-dai-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'DAI', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-usdt-bnb',
    logo: 'thugs/USDT-BNB.png',
    name: 'USDT-BNB TLP',
    token: 'USDT-BNB TLP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x0d29724d1834Fc65869812BAe5d63dce8aCB7921',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetUSDT-BNB',
    earnedTokenAddress: '0x390f28E3919D2c4d9624Ae9AeBe6da25322ED56D',
    earnContractAddress: '0x390f28E3919D2c4d9624Ae9AeBe6da25322ED56D',
    defaultApy: 0.6859,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-usdt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'USDT', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-bliq-bnb',
    logo: 'thugs/BLIQ-BNB.png',
    name: 'BLIQ-BNB LP',
    token: 'BLIQ-BNB LP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0xe835ed82C7d9d018f8F5173EECfBb56f29f38570',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBLIQ-BNB',
    earnedTokenAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
    earnContractAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
    defaultApy: 4.6489,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-bliq-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'BLIQ', 'BNB' ],
    callFee: 0.5
  },
  {
    id: 'street-eos-bnb',
    logo: 'thugs/EOS-BNB.png',
    name: 'EOS-BNB LP',
    token: 'EOS-BNB LP',
    tokenDescription: 'Uses: Traphouse',
    tokenAddress: '0x44e89861096371246ea8e3e86d97781ea24fbb29',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetEOS-BNB',
    earnedTokenAddress: '0xd4572009c7580DEfa0251FFD811b04386Dd41570',
    earnContractAddress: '0xd4572009c7580DEfa0251FFD811b04386Dd41570',
    defaultApy: 0.71789,
    unstableApy: false,
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'thugs-lp',
    oracleId: 'street-eos-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Thugs',
    assets: [ 'EOS', 'BNB' ],
    callFee: 0.5
  }
]