import { useCallback } from 'react';
import vaultAbi from '../../drugs/lib/abi/vault.json';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { VAULT_FETCH_POOL_BALANCES_BEGIN, VAULT_FETCH_POOL_BALANCES_SUCCESS, VAULT_FETCH_POOL_BALANCES_FAILURE } from './constants';
import { fetchTvl, fetchPrice } from '../../utils';
import async from 'async';
import {PoolProps} from '../../hooks/redux/initialState';

// FIXME: this function now fetches all the information related to the pool, not only balances

export function fetchPoolBalances(data: { web3: any; pools: any; }) {
  return (dispatch: (arg0: { type: any; data?: any; }) => void) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: VAULT_FETCH_POOL_BALANCES_BEGIN,
    });

    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise<void>((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // args.error here is only for test coverage purpose.
      const { web3, pools } = data;
      async.map(
        pools,
        (pool: { earnContractAddress: any; oracleId: any; tvl: any; oraclePrice: any; }, callback: (arg0: any, arg1: any) => void) => {
          const earnContract = new web3.eth.Contract(vaultAbi, pool.earnContractAddress);
          async.parallel(
            [
              (              callbackInner: (arg0: any, arg1: number) => any) => {
                fetchTvl({
                  contract: earnContract,
                })
                  .then((data: any) => {
                    return callbackInner(null, data);
                  })
                  .catch((error: any) => {
                    return callbackInner(error, 0);
                  });
              },
              (              callbackInner: (arg0: any, arg1: number) => any) => {
                fetchPrice({
                  id: pool.oracleId,
                })
                  .then((data: any) => {
                    return callbackInner(null, data);
                  })
                  .catch((error: any) => {
                    return callbackInner(error, 0);
                  });
              },
            ],
            (error: any, data: number[]) => {
              if (error) {
                console.log(error);
              }
              pool.tvl = data[0] || 0;
              pool.oraclePrice = data[1] || 0;
              callback(null, pool);
            }
          );
        },
        (error: { message: string; }, pools: PoolProps[]) => {
          if (error) {
            dispatch({
              type: VAULT_FETCH_POOL_BALANCES_FAILURE,
            });
            return reject(error.message || error);
          }
          dispatch({
            type: VAULT_FETCH_POOL_BALANCES_SUCCESS,
            data: pools,
          });
          resolve();
        }
      );
    });

    return promise;
  };
}

export function useFetchPoolBalances() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { pools, fetchPoolBalancesPending } = useSelector(
    (    state: { vault: { pools: PoolProps[]; fetchPoolBalancesPending: boolean; }; }) => ({
      pools: state.vault.pools,
      fetchPoolBalancesPending: state.vault.fetchPoolBalancesPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPoolBalances(data));
    },
    [dispatch]
  );

  return {
    pools,
    fetchPoolBalances: boundAction,
    fetchPoolBalancesPending,
  };
}

export function reducer(state: any, action: { type: any; data: any; }) {
  switch (action.type) {
    case VAULT_FETCH_POOL_BALANCES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolBalancesPending: true,
      };

    case VAULT_FETCH_POOL_BALANCES_SUCCESS:
      // The request is success
      return {
        ...state,
        pools: action.data,
        fetchPoolBalancesPending: false,
      };

    case VAULT_FETCH_POOL_BALANCES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolBalancesPending: false,
      };

    default:
      return state;
  }
}
