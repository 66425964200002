import { pools } from '../../constants/pool';

export interface PoolProps {
  tokenBalance: any;
  id: string,
  logo: string,
  name: string,
  token: string,
  tokenDescription: string,
  tokenAddress: string,
  tokenDecimals: number,
  tokenDescriptionUrl: string,
  earnedToken: string,
  earnedTokenAddress: string,
  earnContractAddress: string,
  defaultApy: number,
  unstableApy: boolean,
  pricePerFullShare: number,
  tvl: number,
  oracle: string,
  oracleId: string,
  oraclePrice: number,
  depositsPaused: boolean,
  status: string,
  platform: string,
  assets: Array<string>,
  callFee: number,
  allowance?: number,
  refundContractAddress?: string,
}

export interface ITokenKeys {
  [key: string]: {tokenAddress: string, tokenBalance: number};
}

const tokens: ITokenKeys = {};

pools.forEach(({ token, tokenAddress, earnedToken, earnedTokenAddress }: {token: string, tokenAddress: string, earnedToken: string, earnedTokenAddress: string}) => {
  tokens[token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0,
  };
  tokens[earnedToken] = {
    tokenAddress: earnedTokenAddress,
    tokenBalance: 0,
  };
});

const initialState = {
  pools,
  tokens,
  contractApy: {},
  fetchContractApyPending: false,
  fetchPoolBalancesPending: false,
  fetchUserPoolBalancesPending: false,
  fetchBalancesPending: false,
  fetchApprovalPending: {},
  fetchDepositPending: {},
  fetchWithdrawPending: {},
  fetchHarvestPending: {},
};

export default initialState;
