import initialState from './initialState';
import { reducer as fetchPoolBalancesReducer } from './fetchPoolBalances';
import { reducer as fetchContractApyReducer } from './fetchContractApy';
import { reducer as fetchBalancesReducer } from './fetchBalances';
import { reducer as fetchUserPoolBalancesReducer } from './fetchUserPoolBalances';
import { reducer as fetchDeposit } from './fetchDeposit';
import { reducer as fetchApproval } from './fetchApproval';
import { reducer as fetchWithdraw } from './fetchWithdraw';
import { reducer as fetchHarvest } from './fetchHarvest';

const reducers = [fetchPoolBalancesReducer, fetchContractApyReducer, fetchBalancesReducer, fetchUserPoolBalancesReducer, fetchDeposit, fetchApproval, fetchWithdraw, fetchHarvest];

export default function reducer(state = initialState, action: any) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
