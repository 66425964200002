import React from 'react'
import styled from 'styled-components'

import { InputProps } from '../Input'

interface TokenInputProps extends InputProps {
  max: number | string,
  symbol: string,
  onSelectMax?: () => void,
}

const TokenInput: React.FC<TokenInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
}) => {
  return (
    <StyledInputWrapper>
      <StyledMaxText>{max.toLocaleString()} {symbol} Available</StyledMaxText>
      <StyledTokenInputContainer>
      <StyledTokenInput
        onChange={onChange}
        placeholder="0"
        value={value}
      />
      <StyledTokenAdornmentWrapper>
          <StyledTokenSymbol>{symbol}</StyledTokenSymbol>
          <StyledSpacer />
          <div>
            <StyledMaxButton onClick={onSelectMax}>
              <StyledMaxFont>MAX</StyledMaxFont>
            </StyledMaxButton>
          </div>
        </StyledTokenAdornmentWrapper>
        </StyledTokenInputContainer>
    </StyledInputWrapper>
  )
}

/*
            <div>
              <Button size="sm" text="Max" />
            </div>
*/

const StyledMaxButton = styled.button`
  background-color: #30304C;
  border: 1px solid ${props => props.theme.color.blue[400]};
  border-radius: 5px;
`

const StyledMaxFont = styled.p`
  color: ${props => props.theme.color.grey[900]};
  font-family: Roboto;
  font-size: 12px;
  margin: 2px;
`

const StyledInputWrapper = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.blue[300]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 12px;
`

const StyledTokenInputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledTokenInput = styled.input`
  background: none;
  border: 0;
  background-color: ${props => props.theme.color.blue[300]};
  color: ${props => props.theme.color.grey[1100]};
  font-family: Roboto;
  font-weight: 700;
  font-size: 18px;
  flex: 1;
  height: 32px;
  margin: 0;
  padding: 0;
  outline: none;
  width: 100px;
`

const StyledSpacer = styled.div`
  width: ${props => props.theme.spacing[3]}px;
`

const StyledTokenAdornmentWrapper = styled.div`
  align-items: center;
  display: flex;
`

const StyledMaxText = styled.div`
  align-items: center;
  color: ${props => props.theme.color.grey[1100]};
  display: flex;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 16px;
`

const StyledTokenSymbol = styled.span`
  color: ${props => props.theme.color.grey[1100]};
  font-family: Roboto;
`

export default TokenInput