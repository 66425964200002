import BigNumber from 'bignumber.js'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Spacer from '../../../components/Spacer'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import Label from '../../../components/Label'
import Value from '../../../components/Value'
import useModal from '../../../hooks/useModal'
import drugslogo from '../../../assets/img/drugslogo.png'
import useTokenBalance from '../../../hooks/useTokenBalance'
import { getBalanceNumber } from '../../../utils/formatBalance'
import DepositModal from './DepositModal'
import { contractAddresses } from '../../../drugs/lib/constants'
import useEnter from '../../../hooks/useEnter'
import useLeave from '../../../hooks/useLeave'
import useAllowanceStaking from '../../../hooks/useAllowanceStaking'

import useApproveStaking from '../../../hooks/useApproveStaking'

interface StakeProps {}

const Drugsv2bal: React.FC<StakeProps> = ({}) => {
  const tokenName = 'NUEVA MORRALLA'
  const [requestedApproval, setRequestedApproval] = useState(false)

  const allowance = useAllowanceStaking()
  const { onApprove } = useApproveStaking()

  const tokenBalance = useTokenBalance(
    '0xadcBAEbaac0f775923eBDFb6B904399DA8801275',
  )

  const { onEnter } = useEnter()
  const { onLeave } = useLeave()

  const [onPresentDeposit] = useModal(
    <DepositModal
      max={tokenBalance}
      onConfirm={onEnter}
      tokenName={tokenName}
    />,
  )

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
          <StyledCardHeader>
            <CardIcon>{<img src={drugslogo} height={104} />}</CardIcon>
            <Value value={getBalanceNumber(tokenBalance)} />
            <Label text={`NUEVA MORRALLA`} />
          </StyledCardHeader>
        </StyledCardContentInner>
      </CardContent>
    </Card>
  )
}

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
`

export default Drugsv2bal
