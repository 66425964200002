import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const getDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
}

export const formatApy = (apy: number, fallbackApy: any) => {
  if (!apy) {
    apy = fallbackApy;
  }
  apy *= 100;

  const order = Math.floor(Math.log10(apy) / 3);
  const units = ['', 'k', 'M', 'B', 'T'];
  const num = apy / 1000 ** order;

  return `${num.toFixed(2)}${units[order]}%`;
};

export const formatTvl = (tvl: number, oraclePrice: number) => {
  // TODO: bignum?
  tvl *= oraclePrice;

  const order = Math.floor(Math.log10(tvl) / 3);
  if (order < 0) {
    return '$0.00';
  }

  const units = ['', 'k', 'M', 'B', 'T'];
  const num = tvl / 1000 ** order;
  const prefix = oraclePrice === 0 ? '~$' : '$';

  return prefix + num.toFixed(2) + units[order];
};

export const calcDaily = (apy: number, fallbackApy: any) => {
  if (!apy) {
    apy = fallbackApy;
  }

  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;
  if (isNaN(g)) {
    return '- %';
  }

  return `${(g * 100).toFixed(2)}%`;
};


export function format(number: BigNumber) {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(10000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(10000))
    .toNumber();
}

export function byDecimals(number: number, tokenDecimals = 18) {
  const decimals = new BigNumber(10).exponentiatedBy(tokenDecimals);
  return new BigNumber(number).dividedBy(decimals);
}

export function calculateReallyNum(total: number, sliderNum: number) {
  if (sliderNum === undefined) {
    return byDecimals(0, 0).toFormat(4);
  }
  return byDecimals((sliderNum / 100) * Number(total), 0).toFormat(4);
}
