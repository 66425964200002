import axios from 'axios';
import { pools } from '../constants/pool';

const endpoints = {
  thugsLp:   'https://api.beefy.finance/thugs/lps',
  thugs:     'https://api.beefy.finance/thugs/tickers',
  bakeryLp:  'https://api.beefy.finance/bakery/lps',
  jetfuelLp: 'https://api.beefy.finance/jetfuel/lps',
  narwhalLp: 'https://api.beefy.finance/narwhal/lps',
};

const WBNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
const BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
const WBNB_BUSD = `${WBNB}_${BUSD}`;

const CACHE_TIMEOUT_MS = 1 * 60 * 1000; // 1 minute(s)

interface PriceCacheProps {
  cache: Map<string, number>,
  lastUpdated: Date | undefined, 
}

const priceCache: PriceCacheProps = {
  cache: new Map(),
  lastUpdated: new Date()
}

function isCached(id: string) {
  return priceCache.cache.has(id)
}

function getCachedPrice(id: string) {
  return priceCache.cache.get(id);
}

function maybeUpdateCache() {
  const currentTimestamp = new Date()
  if (!(priceCache.lastUpdated === undefined) || currentTimestamp.getTime() > priceCache.lastUpdated.getTime() + CACHE_TIMEOUT_MS) {
    initializePriceCache()
    console.trace('price cache updated')
  }
}


const fetchThugs = async () => {
  try {
    const response = await axios.get(endpoints.thugs);
    const bnb = response.data[WBNB_BUSD]['last_price'];
    const prices: {
      [key:string]: number
    } = {}

    for (let pair in response.data) {
      const ticker = response.data[pair];

      let price = 0;
      if (pair === `${WBNB}_${BUSD}`) {
        price = bnb;
      } else if (pair.startsWith(`${WBNB}_`)) {
        price = bnb / ticker['last_price'];
      } else {
        price = bnb * ticker['last_price'];
      }

      prices[pair] = price
    }

    return prices;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const fetchLP = async (endpoint: string) => {
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

interface EndPointProps {
  [key:string]: () => Promise<any>
}

const oracleEndpoints: EndPointProps = {
  
  'thugs': () => fetchThugs(),
  'thugs-lp': () => fetchLP(endpoints.thugsLp),
  'bakery-lp': () => fetchLP(endpoints.bakeryLp),
  'jetfuel-lp': () => fetchLP(endpoints.jetfuelLp),
  'narwhal-lp': () => fetchLP(endpoints.narwhalLp),
}

export async function initializePriceCache () {
  const currentTimestamp = new Date()
  priceCache.lastUpdated = currentTimestamp

  const oracleToIds = new Map();
  pools.forEach(pool => {
    if (!oracleToIds.has(pool.oracle)) {
      oracleToIds.set(pool.oracle, []);
    }
    oracleToIds.get(pool.oracle).push(pool.oracleId);
  })

  const promises = [...oracleToIds.keys()].map((key: string) => oracleEndpoints[key]());
  const results = await Promise.all(promises);
  const allPrices = results.reduce((accPrices, curPrices) => ({...accPrices, ...curPrices}), {});
  [...oracleToIds.values()].flat().forEach(id => priceCache.cache.set(id, allPrices[id]));
}


export const fetchPrice = async ({ id }: {id: string}) => {
  if (id === undefined) {
    console.error('Undefined pair');
    return 0;
  }
  
  let counter = 0 // safe guard, though it shouldn't happen
  while (!isCached(id) && counter < 10) {
    console.trace(id, 'price not cached');
    counter++;
  }

  maybeUpdateCache()

  return getCachedPrice(id) ? getCachedPrice(id) : 0;
};
